import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Img from 'gatsby-image/withIEPolyfill';
import { Odin } from '../src/components/Odin/Odin';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import { Freya } from '../src/components/Freya/Freya';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

import './pys_recomendaciones.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query pys_recomendaciones($locale: String!) {
    allPysRecomendacionesSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPysRecomendacionesBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allPysRecomendacionesCabecera(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          body
        }
      }
    }
    allPysRecomendacionesShowCaseTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allPysRecomendacionesImageDescTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allPysRecomendacionesImageDesc(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title
          description
          image
          image_alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPysRecomendacionesShowCase(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          summary
          call_to_action_copy
          call_to_action_path
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
  }
`;

class PysTrucos extends React.Component {
  state = {};

  freyaData = {
    cards: this.props.data.allPysRecomendacionesShowCase.edges.map((item, index) => ({
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      description: item.node.description,
      imgsize: 'large',
      index,
      buttons: {
        cta: false,
        size: 'small',
        color: 'orange',
        ctaText: item.node.call_to_action_copy,
        blank1: true,
      },
    })),
  };

  render() {
    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allPysRecomendacionesSeoBlock.edges[0].node._0.title}
          description={this.props.data.allPysRecomendacionesSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <div className="general-index">
            <div className="pys-recomendaciones-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allPysRecomendacionesBreadcrumb.edges[0].node.title}
              />
              <div className="pys-recomendaciones-cabecera">
                <h1>{this.props.data.allPysRecomendacionesCabecera.edges[0].node.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allPysRecomendacionesCabecera.edges[0].node.description,
                  }}
                />
              </div>
              <div
                className="pys-recomendaciones-descripcion"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allPysRecomendacionesCabecera.edges[0].node.body,
                }}
              />
              <h2 className="pys-recomendaciones-title">
                {this.props.data.allPysRecomendacionesImageDescTitle.edges[0].node.title}
              </h2>
              <div className="pys-recomendaciones-imagen-desktop">
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[0].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[0].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[0].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[0].node.image}
                  />
                </div>
                <div className="pys-recomendaciones-imagen-desc2">
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[1].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[1].node.image}
                  />
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[1].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[1].node
                          .description,
                      }}
                    />
                  </div>
                </div>
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[2].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[2].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[2].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[2].node.image}
                  />
                </div>
                <div className="pys-recomendaciones-imagen-desc2">
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[3].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[3].node.image}
                  />
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[3].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[3].node
                          .description,
                      }}
                    />
                  </div>
                </div>
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[4].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[4].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[4].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[4].node.image}
                  />
                </div>
              </div>
              <div className="pys-recomendaciones-imagen-mobile">
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[0].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[0].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[0].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[0].node.image}
                  />
                </div>
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[1].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[1].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[1].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[1].node.image}
                  />
                </div>
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[2].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[2].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[2].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[2].node.image}
                  />
                </div>
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[3].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[3].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[3].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[3].node.image}
                  />
                </div>
                <div className="pys-recomendaciones-imagen-desc">
                  <div className="pys-recomendaciones-imagen-text">
                    <div className="pys-recomendaciones-imagen-title">
                      {this.props.data.allPysRecomendacionesImageDesc.edges[4].node.title}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.allPysRecomendacionesImageDesc.edges[4].node
                          .description,
                      }}
                    />
                  </div>
                  <img
                    alt={this.props.data.allPysRecomendacionesImageDesc.edges[4].node.image_alt}
                    src={this.props.data.allPysRecomendacionesImageDesc.edges[4].node.image}
                  />
                </div>
              </div>
              <div className="pys-freya-title">
                <h2>{this.props.data.allPysRecomendacionesShowCaseTitle.edges[0].node.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allPysRecomendacionesShowCaseTitle.edges[0].node
                      .description,
                  }}
                />
              </div>
              <Freya data={this.freyaData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PysTrucos;
